import * as React from "react";

export default function delay(seconds){
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000)  
  });
}


export function Countdown({seconds}) {
  const [counter, setCounter] = React.useState(seconds);

  React.useEffect(() => {
    let repeat;
    if(counter > 0){
      repeat = setTimeout(() => setCounter(counter - 1), 1000)
    }

    return () => {
      if(repeat){
        clearTimeout(repeat)
      }
    }
  }, [counter]);

  return counter;
}

export function callFundctionAfterSeconds(callback, deadlineSecs) {
  var secs = 0;
  var interval = 60000 / 60; //1000; // ms
  var expected = Date.now() + interval;
  setTimeout(step, interval);

  function step() {
    var dt = Date.now() - expected;
    secs++;
    // console.log(secs, '('+(interval - (interval - dt))+ 'ms off)')
    expected += interval;
    if (secs < deadlineSecs) {
      setTimeout(step, Math.max(0, interval - dt)); // take into account drift
    } else {
      callback();
    }
  }
}
