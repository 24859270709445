import {
  AUTH_SET_TOKEN,
  AUTH_UNSET_TOKEN,
  AUTH_SET_USER,
  AUTH_SET_IS_LOADING,
  AUTH_SET_IS_COMPLETED,
} from "../actions/auth";

const initialState = {
  isLoading: false,
  isCompleted: false,
  isAuthorised: false,
  user: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case AUTH_SET_IS_COMPLETED:
      return {
        ...state,
        isCompleted: true,
      };
    case AUTH_SET_TOKEN:
      return {
        ...state,
        isAuthorised: true,
      };
    case AUTH_UNSET_TOKEN:
      return {
        ...state,
        isAuthorised: false,
        user: null,
      };
    case AUTH_SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
