import axs from 'axios';

export const baseURL = process.env.REACT_APP_API_URL;
export const cdnURL = process.env.REACT_APP_CDN_URL;

const axios = axs.create({
  baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  },
});

const cancelTokenSource = {};

export default {
  async get(url, params, headers, cancelType = false /* true || full */) {

    const requestKey = cancelType && btoa(url + (cancelType === 'full' ? JSON.stringify(params) : ''));
    if (requestKey) {
      if (cancelTokenSource[requestKey]) {
        cancelTokenSource[requestKey].cancel();
      }
      cancelTokenSource[requestKey] = axs.CancelToken.source();
    }

    try {
      const response = await axios.get(url, { params, cancelToken: cancelType && cancelTokenSource[requestKey].token, headers: { ...headers } });
      return response;
    } catch (err) {
      throw err;
    }
  },
  async post(url, params, headers) {
    const response = await axios.post(url, params, { headers: { ...headers } });
    return response;
  },
  async put(url, params, headers) {
    try {
      const response = await axios.put(url, params, { headers: { ...headers } });
      return response;
    } catch (err) {
      throw err;
    }
  },
  async patch(url, params, headers) {
    try {
      const response = await axios.patch(url, params, { headers: { ...headers } });
      return response;
    } catch (err) {
      throw err;
    }
  },
  async delete(url, params, headers) {
    try {
      return await axios.delete(url, { params, headers: { ...headers } });
    } catch (err) {
      throw err;
    }
  },
  async download(url, filename, type, params, headers) {
    try{
      const response = await axios.get(url, { params, headers: { ...headers } });

      const fileType = type || response.headers['content-type'] && response.headers['content-type'].split(';').join('').split(' ')[0] || 'application/octet-stream';
      const fileName = filename || response.headers['content-disposition'] && response.headers['content-disposition'].substring(21)

      const _url = window.URL.createObjectURL(new Blob([response.data], {type: fileType}))
      
      const a = document.createElement("a");
      a.style = "display: none";
      a.href = _url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(_url);
      a.remove();
    }catch(err){
      console.log(err);
    }
  },
  axios,
};
