import api from './../../shared/functions/api/';
import { history } from "../../App";

export const SITE_SET_BLOCKS = "SITE_SET_BLOCKS";
export const SITE_SET_CCY = "SITE_SET_CCY";
export const SITE_SET_CCY_API = "SITE_SET_CCY_API";
export const SITE_SET_OPEN_GENERAL_DIALOG = "SITE_SET_OPEN_GENERAL_DIALOG";
export const SITE_SET_FXRATES = "SITE_SET_FXRATES";
export const SITE_SET_SOCIAL_LINKS = "SITE_SET_SOCIAL_LINKS";
export const SITE_SET_DIAMOND_AVAILABILITY = "SITE_SET_DIAMOND_AVAILABILITY";
export const SITE_SET_DIALOG_SETTINGS = "SITE_SET_DIALOG_SETTINGS";
export const SITE_SET_HIDE_LAYOUT = "SITE_SET_HIDE_LAYOUT";

export const setSiteBlocks = (siteBlocks) => {
  return {
    type: SITE_SET_BLOCKS,
    data: siteBlocks,
  };
};

export const fetchSiteBlocks = () => {
  return (dispatch) => {
    return api.get('/configs/site-blocks')
    .then((res) => {
      const siteBlocks = {};
      res.data.forEach(s => { siteBlocks[s._id] = s.fields; });
      dispatch(setSiteBlocks(siteBlocks));
    })
    .catch((err) => {
      console.log(err)
    });
  }
};

export const setSiteFXRates = (fxrates) => {
  return {
    type: SITE_SET_FXRATES,
    data: fxrates,
  };
};

export const fetchFXRates = () => {
  return (dispatch) => {
    return api.get('/configs/fxrates')
    .then((res) => {
      dispatch(setSiteFXRates(res.data));
    })
    .catch((err) => {
      console.log(err)
    });
  }
};

export const setSiteSocialLinks = (fxrates) => {
  return {
    type: SITE_SET_SOCIAL_LINKS,
    data: fxrates,
  };
};

export const fetchSocialLinks = () => {
  return (dispatch) => {
    return api.get('/configs/social-links')
      .then((res) => {
        dispatch(setSiteSocialLinks(res.data));
      })
      .catch((err) => {
       console.log(err)
      });
  }
};

export const setCCY = (ccy) => {
  return {
    type: SITE_SET_CCY,
    data: ccy,
  };
};

export const setCCYAPI = (ccyAPI) => {
  return {
    type: SITE_SET_CCY_API,
    data: ccyAPI,
  };
};

export const setOpenGeneralDialog = (dialogProps) => {
  return {
    type: SITE_SET_OPEN_GENERAL_DIALOG,
    data: dialogProps,
  };
};

export const setSiteDiamondAvailability = (diamond_available) => {
  return {
    type: SITE_SET_DIAMOND_AVAILABILITY,
    data: diamond_available,
  };
};

export const fetchDiamondAvailability = () => {
  return (dispatch) => {
    return api.get('/configs/diamond-availability')
      .then((res) => {
        dispatch(setSiteDiamondAvailability(res.data.fields));
      })
      .catch((err) => {
       console.log(err)
      });
  }
};

export const setDialogSettings = (dialogSettings) => {
  return {
    type: SITE_SET_DIALOG_SETTINGS,
    data: dialogSettings,
  };
};

export const fetchDialogSettings = () => {
  return (dispatch) => {
    return api.get('/configs/dialog-settings')
      .then((res) => {
        dispatch(setDialogSettings(res.data.fields));
      })
      .catch((err) => {
       console.log(err)
      });
  }
};

export const setSiteHideLayout = (isLayoutHidden) => {
  return {
    type: SITE_SET_HIDE_LAYOUT,
    data: isLayoutHidden,
  };
};