import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { persistStore } from "redux-persist";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
);

export default function (initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  //console.log("initialState", store.getState());
  const persistor = persistStore(store, null, () => {
    // if you want to get restoredState
    //console.log("restoredState", store.getState());
  });
  return { store, persistor };
}
