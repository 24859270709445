
export const HOME_SET_ANIMATION_STARTED = "HOME_SET_ANIMATION_STARTED";

export const setAnimationStarted = () => {
  return {
    type: HOME_SET_ANIMATION_STARTED,
    data: true,
  };
};

