import { createGlobalStyle } from 'styled-components';

import MinionPro from './MinionPro-Disp.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'MinionPro-Disp';
        src: local('MinionPro-Disp'), local('MinionPro-Disp'),
        url(${MinionPro}) format('woff2'),
        url(${MinionPro}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
`;