import api, { baseURL } from './../../shared/functions/api/';
import { history } from "../../App";
import { io } from "socket.io-client";
import { loadDiamondsData } from "./../../redux/actions/diamonds";
import { setOpenGeneralDialog } from "./../../redux/actions/site";

export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const AUTH_UNSET_TOKEN = "AUTH_UNSET_TOKEN";
export const AUTH_SET_USER = "AUTH_SET_USER";
export const AUTH_SET_IS_LOADING = "AUTH_SET_IS_LOADING";
export const AUTH_SET_IS_COMPLETED = "AUTH_SET_IS_COMPLETED";

export const setAuthLoading = (isLoading) => {
  return {
    type: AUTH_SET_IS_LOADING,
    data: isLoading,
  };
};

export const setAuthIsCompleted = () => {
  return {
    type: AUTH_SET_IS_COMPLETED,
    data: true,
  };
};

export const setAuthUser = (user) => {
  if (user._id) {
    localStorage.setItem("_id", user._id);
  }
  return {
    type: AUTH_SET_USER,
    data: user,
  };
};

export const loadAuthUser = () => {
  return (dispatch) => {
    return api.get("/profile").then((res) => {
      dispatch(setAuthUser(res.data));
    });
  };
};

export const emailCheck = (email) => {
  return async dispatch => {
    try {
      const res = await api.post("/emailCheck", { email });
      return { exists: true, ...res.data };
    } catch (err) {
      if (err.response && err.response.data.errors.msg === "USER_DOES_NOT_EXIST") {
        return { exists: false, ...err.response.data };
      }
      if (err.response && err.response.data.errors.msg === "CURRENT_CLIENT") {
        return { exists: true, ...err.response.data };
      }
    }
  };
};

export const setAuthToken = (token) => {
  localStorage.setItem("token", token);
  return {
    type: AUTH_SET_TOKEN,
    data: true,
  };
};

export const unsetAuthToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("_id");
  api.axios.defaults.headers.common = { Authorization: `` };
  socket && socket.disconnect();
  return {
    type: AUTH_UNSET_TOKEN,
    data: false,
  };
};

export const checkAuth = (dontCheckRemote = false) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(unsetAuthToken());
      dispatch(setAuthLoading(false));
      dispatch(setAuthIsCompleted());
    } else {
      // since checkAuth is called in App/AuthRoute, we need to inject the token in api headers for subsequent requests to API
      api.axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

      if (dontCheckRemote) {
        dispatch(setAuthToken(token));
        dispatch(setAuthLoading(false));
        dispatch(setAuthIsCompleted());
      } else {
        // we need to test the application stored token with API to see if it's valid anyways!
        return api
          .get("/token")
          .then((res) => {
            dispatch(setAuthToken(token));
            dispatch(loadAuthUser());
            dispatch(setAuthLoading(false));
            dispatch(setAuthIsCompleted());
            connectWebSocket(token, dispatch);
          })
          .catch((err) => {
            dispatch(unsetAuthToken());
            dispatch(setAuthLoading(false));
            dispatch(setAuthIsCompleted());
          });
      }
    }
  };
};

let socket;

const connectWebSocket = (token, dispatch) => {
  socket = io(baseURL, {
    /* reconnectionDelay: 1000,
    reconnection: true,
    reconnectionAttemps: 10, */
    reconnect: true,
    transports: ['websocket'],
    agent: false,
    upgrade: false,
    rejectUnauthorized: false
  });

  socket.on("connect", () => {
    if(process.env.NODE_ENV !== "production") console.log('socket.id', socket.id);
    if(process.env.NODE_ENV !== "production") console.log('socket.connected', socket.connected);

    socket.on('logout', () => {
      if(process.env.NODE_ENV !== "production") console.log('logout from socket')
      dispatch(unsetAuthToken());
      document.location.href = '/'; 
    })

    socket.emit('addTokenToLoggedUsers', token);
  });
}

export const login = (email, password, rememberMe, after) => {
  return (dispatch) => {
    return api
      .post("/login", {
        email,
        password,
        rememberMe,
      })
      .then((res) => {
        dispatch(setAuthToken(res.data.token));
        dispatch(setAuthUser(res.data.user));
        dispatch(checkAuth());
        dispatch(loadDiamondsData());
        if (after) {
          // we are using a real redirect here because for some reason the history.push wasn't allowing prices to refresh when logged in from diamonds page
          setTimeout(() => {
            document.location.href = after; 
          }, 1000)
        } else {
          history.push(res.data.user.role === 'user' ? "/my-account" : '/admin');
        }
      });
  };
};

export const addToBag = (stock, onSuccess=()=>{}, onError=()=>{}, addAnyway=false) => {
  return (dispatch) => {
    return api.put(`/profile/bag/${stock._id}`, {stockObj: stock, addAnyway})
      .then((res) => {

        dispatch(setAuthUser(res.data.authUser));

        // if item wasn't available but now it is: 
        if(stock.Availability === 2 && res.data.stockItem.Availability === 1){
          dispatch(setOpenGeneralDialog({
            headline: 'Good news',
            description: 'This item has just been returned to stock and will be available immediately',
            onComplete: () => {
              onSuccess();
              dispatch(setOpenGeneralDialog(false));
            },
            onClose: () => {
              onSuccess();
              dispatch(setOpenGeneralDialog(false));
            },
            hideBackButton: true,
          }));
          dispatch(loadDiamondsData());
          return;
        }

        onSuccess();
      })
      .catch((err) => {

        const errorMsg = err && err.response && err.response.data && err.response.data.error;

        if(errorMsg){

          let headline = 'Error';
          let description = '';

          let onComplete = () => {
            dispatch(setOpenGeneralDialog(false));
          };

          let hideBackButton = true;
          let onBack = () => {
            dispatch(setOpenGeneralDialog(false));
          };

          let submitLabel = 'OK';
          let backLabel = 'Cancel';

          if(errorMsg === 'STOCK_NOT_AVAILABLE'){
            headline = 'This item is not available';
            description = 'The item requested has just been sent out "On Memo" - and will only be available in 3-5 days - Are you sure you want to add it to your basket?';
            hideBackButton = false;

            backLabel = 'No';
            submitLabel = 'Yes';
            onComplete = () => {
              dispatch(setOpenGeneralDialog(false));
              dispatch(addToBag(stock, onSuccess, onError, true));
            };
          }
          if(errorMsg === 'STOCK_WAS_IN_BAG'){
            description = 'This item is already in your bag';
          }
          if(errorMsg === 'STOCK_NOT_FOUND'){
            headline = 'This item is not available';
            description = 'Unfortunately, the item requested has just been sold on our site. Please continue to browse for alternatives';
          }

          dispatch(setOpenGeneralDialog({
            headline,
            description,
            onComplete,
            submitLabel,
            backLabel,
            onBack,
            hideBackButton,
          }));

          dispatch(loadDiamondsData());

          onError();
        }
      
      })
  };
};

export const updateItemInBag = (stock, fieldName, value) => {
  
  stock = Array.isArray(stock) ? stock.join(',') : stock;

  const data = {
    [fieldName]: value,
  };

  if (['weight', 'items'].includes(fieldName)) {
    data.selectedUnit = fieldName;
  }

  return (dispatch) => {
    return api.patch(`/profile/bag/${stock}`, data)
      .then((res) => {
        dispatch(setAuthUser(res.data));
      })
      .catch((err) => {
        console.log(err)
      })
  };
};

export const removeFromBag = (stock) => {
  return (dispatch) => {
    return api.delete(`/profile/bag/${stock}`)
      .then((res) => {
        dispatch(setAuthUser(res.data));
      })
      .catch((err) => {
        console.log(err)
      })
  };
};
