import {
  DIAMONDS_SET_DATA,
  DIAMONDS_SET_COMPARE,
  DIAMONDS_SET_FAVOURITE,
  DIAMONDS_SET_PAGE_STATE,
} from "../actions/diamonds";

const initialState = {
  data: false,
  compare: [],
  favourite: [],
  pageStateSingles: false,
  pageStateSinglesLab: false,
  pageStateParcels: false,
};

export default function diamonds(state = initialState, action) {
  switch (action.type) {
    case DIAMONDS_SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case DIAMONDS_SET_COMPARE:
      return {
        ...state,
        compare: action.data,
      };
    case DIAMONDS_SET_FAVOURITE:
      return {
        ...state,
        favourite: action.data,
      };
    case DIAMONDS_SET_PAGE_STATE:
      return {
        ...state,
        pageStateSingles: action.data.tabsValue === 0 ? {...(state.pageStateSingles || {}), ...action.data} : state.pageStateSingles,
        pageStateSinglesLab: action.data.tabsValue === 1 ? {...(state.pageStateSinglesLab || {}), ...action.data} : state.pageStateSinglesLab,
        pageStateParcels: action.data.tabsValue === 2 ? {...(state.pageStateParcels || {}), ...action.data} : state.pageStateParcels,
      };
    default:
      return state;
  }
}
