import React from "react";
import { Box, withStyles, IconButton } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Form } from 'react-final-form';
import styled, { createGlobalStyle } from 'styled-components';
import classNames from 'classnames';
import HighlightedInformation from "../../shared/components/HighlightedInformation";
import { toTitleCase } from './../../shared/functions/strings';

const HideTrengoChat = createGlobalStyle`
#trengo-web-widget{
  display: none !important;
}
`;

const DialogTitleStyled = styled(DialogTitle)`
  h2{
    font-family: MinionPro-Disp;
    font-size: 55px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #313438;
    text-align: ${props => props.align || 'center'};
  }
`;

const DialogContentTextStyled = styled(DialogContentText)`
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #b6b8bc;
`;

const DialogContentStyled = styled(DialogContent)`
overflow-y: hidden;

.MuiFormControl-root .MuiTypography-body1{ 
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #b08f6e;
}
svg{
  color: #b08f6e;
}
`;

const DialogActionsStyled = styled(DialogActions)`
  margin-top: 120px;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  padding-bottom: 0;

  button {
    height: 33px;
    width: 111px;
    min-width: max-content;
    padding: 0;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #b08f6e;
    background-color: #ffffff;
    border: solid 1px #b6b8bc;
    box-shadow: none !important;
    border-radius: 0;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
        background-color: inherit;
    }

    &.MuiButton-containedSecondary {
        color: #ffffff !important;
        border: solid 1px #b08f6e;
        background-color: #b08f6e;
    }

    &:disabled{
        opacity: 0.8;
    }
  }
`;


const styles = theme => ({
  dialogPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 100,
    paddingLeft: 88,
    paddingRight: 88,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 40,
      paddingBottom: 40,
    },
    '&.MuiDialog-paperWidthSm': {
      maxWidth: 528,
    },
    '&.MuiDialog-paperWidthLg': {
      maxWidth: 1200,
    },
  },
  dialogPaperColumnLayout: {
    padding: 46,
  },
  dialogPaperScrollPaper: {
    maxHeight: "98vh"
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid rgba(141, 146, 152, 0.30)',
    }
  },
  contentWrap: {
    paddingTop: 25,
    paddingBottom: 0
  },
  closeButton: {
    position: 'absolute', 
    top: 40, 
    right: 40,
    [theme.breakpoints.down("sm")]: {
      top: 10, 
      right: 10,
    },
  }
});

const ColumnsLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  div{
    &:first-child{
      flex: 0 0 310px;
    }
  }
`;


function FormDialog(props) {
  const {
    classes,
    open,
    onClose,
    headline,
    description,
    onFormSubmit,
    content,
    actions,
    maxWidth,
    initialValues,
    validate,
    columnsLayout,
    actionsStyle,
    mutators,
    status,
    statusOptions,
    formStyle={},
  } = props;

  function renderStatusOptions(statusOptions){
    return statusOptions && Object.keys(statusOptions).filter(optionName => optionName !== 'customMessage').map(optionName => <p style={{fontSize: 12}}>{toTitleCase(optionName.toString().split('_').join(' '))}: {toTitleCase(statusOptions[optionName].toString().split('_').join(' '))}</p>) || '';
  }

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      validate={validate}
      mutators={mutators}
      render={({ handleSubmit, submitting, pristine, form, values }) => (
        <Dialog
          disableEnforceFocus={true}
          maxWidth={maxWidth}
          open={open}
          onClose={onClose}
          disableBackdropClick={submitting}
          disableEscapeKeyDown={submitting}
          classes={{
            paper: classNames(classes.dialogPaper, columnsLayout && classes.dialogPaperColumnLayout),
            paperScrollPaper: classes.dialogPaperScrollPaper
          }}
        >
          <HideTrengoChat />
          <form onSubmit={handleSubmit} noValidate style={{ width: '100%', maxHeight: '100vh', ...formStyle }}>

            <IconButton
              onClick={onClose}
              className={classes.closeButton}
              disabled={submitting}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>

            {!columnsLayout && <React.Fragment>
              <DialogTitleStyled>
                {headline}
              </DialogTitleStyled>

              <DialogContentStyled className={classes.dialogContent}>
                {description &&
                  <DialogContentTextStyled>
                    {description}
                  </DialogContentTextStyled>
                }

                {status && (<React.Fragment>
                  <br />
                  <HighlightedInformation>
                    <b>{typeof status === 'string' ? toTitleCase(status.toString().split('_').join(' '), statusOptions && statusOptions.customMessage) : status}</b>
                    {renderStatusOptions(statusOptions)}
                  </HighlightedInformation>
                </React.Fragment>)}

                {content &&
                  <div className={classes.contentWrap}>
                    {React.isValidElement(content) ? content : content({ submitting, form, values })}
                  </div>
                }
              </DialogContentStyled>
            </React.Fragment>}

            {columnsLayout && <React.Fragment>
              <ColumnsLayout>
                <div>
                  <DialogTitleStyled align="left">
                    {headline}
                  </DialogTitleStyled>
                  {description &&
                    <DialogContentStyled className={classes.dialogContent}>
                      <DialogContentTextStyled style={{ textAlign: 'left' }}>
                        {description}
                      </DialogContentTextStyled>
                    </DialogContentStyled>
                  }
                  {status && (<React.Fragment>
                    <br />
                    <HighlightedInformation>
                    <b>{typeof status === 'string' ? toTitleCase(status.toString().split('_').join(' '), statusOptions && statusOptions.customMessage) : status}</b>
                    {renderStatusOptions(statusOptions)}
                    </HighlightedInformation>
                  </React.Fragment>)}
                </div>
                {content &&
                  <div>
                    <DialogContentStyled className={classes.dialogContent}>
                      {content && React.isValidElement(content) ? content : content({ submitting, form, values })}
                    </DialogContentStyled>
                  </div>
                }
              </ColumnsLayout>
            </React.Fragment>}

            {actions &&
              <DialogActionsStyled style={actionsStyle}>
                {React.isValidElement(actions) ? actions : actions({ submitting, pristine, form, values })}
              </DialogActionsStyled>
            }

          </form>
        </Dialog>
      )}
    />
  );
}

export default withStyles(styles, { withTheme: true })(FormDialog);
