import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Button, withStyles, Box } from "@material-ui/core";
import FormDialog from "../../../shared/components/FormDialog";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "../../../redux/actions/auth";
import styled from 'styled-components';
import sleep from './../../../shared/functions/sleep';

const styles = (theme) => ({});

class MessageDialog extends PureComponent {

  state = {
    status: false,
  }

  componentDidMount() {
    this.setState({ status: this.props.status })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status })
    }
  }

  onFormSubmit = async (data) => {
    const { history } = this.props;

    this.props.onComplete(data)
  };

  render() {
    const { classes, onClose, headline, description, hideBackButton, submitLabel, onBack, initialValues, backLabel, content } = this.props;
    const { status } = this.state;

    return (
      <Fragment>
        <FormDialog
          open
          status={status && status.general}
          onClose={onClose}
          onFormSubmit={(data) => this.onFormSubmit(data)}
          headline={headline}
          description={description}
          content={content}
          maxWidth={"sm"}
          actions={({ submitting, pristine, values }) =>
            <Fragment>
              {!hideBackButton &&
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  disabled={submitting}
                  onClick={() => onBack ? onBack({ initialValues, values }) : onClose()}
                >
                  {backLabel || 'Back'}
              </Button>
              }

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                endIcon={submitting && <ButtonCircularProgress />}
              >
                {submitLabel || 'OK'}
              </Button>
            </Fragment>
          }
          actionsStyle={hideBackButton ? { justifyContent: 'center', gap: 100, marginTop: 50 } : { justifyContent: 'space-between', marginTop: 50 }}
        />
      </Fragment>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators({ ...AuthActions }, dispatch)
)(withRouter(withStyles(styles)(MessageDialog)));
