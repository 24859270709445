import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import auth from './auth';
import home from './home';
import diamonds from './diamonds';
import site from './site';

const rootReducer = combineReducers({
    auth,
    home,
    diamonds: persistReducer(
        {
            key: "diamonds", // key for localStorage key, will be: "persist:diamonds"
            storage,
            blacklist: ["data", "pageStateSingles", "pageStateSinglesLab", "pageStateParcels"], // don't cache these because we always want them clear at page re-load
        },
        diamonds
    ),
    site,
});

const persistConfig = {
    key: "root",
    storage,
    blacklist: [
        "diamonds", // this is used for the nested config so we don't cache the diamonds.data
        "auth", // auth is excluded because we want to keep it out of cache too
        "home",
        "site", // removed from persistance to see if the fx rates and other live content doesn't get cached
    ] 
};

export default persistReducer(persistConfig, rootReducer);
