import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { PersistGate } from "redux-persist/lib/integration/react";

import smoothscroll from 'smoothscroll-polyfill';
 
// kick off the polyfill!
//window.__forceSmoothScrollPolyfill__ = true; use this to always force polyfill 
smoothscroll.polyfill();

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
