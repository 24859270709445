import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { Router, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import MinionPro from './fonts/MinionPro/MinionPro-Disp';
import Lato from './fonts/Lato/Lato';

import { createBrowserHistory } from "history";

import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./redux/actions/auth";
import { loadDiamondsData } from "./redux/actions/diamonds";
import { fetchSiteBlocks, fetchFXRates, fetchSocialLinks, fetchDiamondAvailability, fetchDialogSettings } from "./redux/actions/site";

import isDarkModeOn from './shared/functions/dark';
import delay, { Countdown, callFundctionAfterSeconds } from './shared/functions/delay';
import styled, { createGlobalStyle } from 'styled-components';

import { useClearCache } from 'react-clear-cache';

import MessageDialog from "./logged_out/components/dialog/MessageDialog";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SiteActions from "redux/actions/site";

// disable website right click:
document.oncontextmenu = document.body.oncontextmenu = function() {return false;}


export const history = createBrowserHistory();

const LoggedInComponent = lazy(() => import("./logged_in_admin/components/Main"));

const LoggedInUserComponent = lazy(() => import("./logged_in_user/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

const delayedRedirect = async url => {
  await delay(5);
  history.push(url)
}

const AuthRoute = ({ children, role, ...rest }) => {
  //const isLoading = useSelector((state) => state.auth.isLoading);
  const isCompleted = useSelector((state) => state.auth.isCompleted);
  const isAuthorised = useSelector((state) => state.auth.isAuthorised);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthorised) {
      if (!isCompleted) {
        dispatch(checkAuth());
      } else {
        delayedRedirect('/')
      }
    }else if(user){
      if(user.role !== role){
        history.push('/')
      }
    }
  }, [isCompleted, isAuthorised, user]); // the empty array means run effect only once

  useEffect(() => {
    dispatch(fetchFXRates());
  }, []);

  return isCompleted ? (
    <Route
      {...rest}
      render={(props) => (isAuthorised && user ? children : <div style={{ padding: 10 }}>Logged out. Redirecting to home page in <Countdown seconds={5} /></div>)}
    />
  ) : '';
};

const PublicRoute = (props) => {
  //const isLoading = useSelector((state) => state.auth.isLoading);
  const isCompleted = useSelector((state) => state.auth.isCompleted);
  const isAuthorised = useSelector((state) => state.auth.isAuthorised);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthorised) {
      if (!isCompleted) {
        dispatch(checkAuth());
      } 
    }
  }, [isCompleted, isAuthorised]); // the empty array means run effect only once

  useEffect(() => {
    dispatch(fetchSiteBlocks());
    dispatch(fetchFXRates());
    dispatch(fetchSocialLinks());
    dispatch(fetchDiamondAvailability());
    dispatch(fetchDialogSettings());
  }, []);

  return (
    <Route {...props} />
  );
};



const DarkSupportWrap = createGlobalStyle`
  body{
    
    
    .dark-support{
      filter: invert(1) hue-rotate(180deg);
      background-color: #eee !important;

      div:not(li div){
        background-color: #eee !important;
      }

      .MuiTableCell-root{
        background-color: #eee !important;
      }

      .MainText, h1{
        color: #999 !important;
      }

      p{
        color: #555 !important;
      }
    }
  .dark-support img {
    filter: invert(1) hue-rotate(180deg);
  }
}
`;

const DarkSupport = () => {
  return !isDarkModeOn() ? '' : <DarkSupportWrap />;
}


const WithTheHelpOfHeaven = () => {
  return <span style={{position: 'absolute', top: 6, right: 6, color: '#8B6647', zIndex: 333333333, fontSize: 8, lineHeight: 1}}>
    בס״ד
  </span>
}

const App = ({isLayoutHidden, openGeneralDialog, SiteActions: { setOpenGeneralDialog }}) => {
  const { isLatestVersion, latestVersion, emptyCacheStorage } = useClearCache();
  console.log('isLatestVersion:', isLatestVersion, 'latestVersion:', latestVersion)

  // auto cache-buster
  if(!isLatestVersion){
    setTimeout(() => emptyCacheStorage(), 300)
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadDiamondsData());
  }, []);

  const closeDialog = () => {
    setOpenGeneralDialog(false)
  };

  /* 

  const openPopup = (headline, description) => {
    setOpenGeneralDialog({
      headline,
      description,
      onComplete: () => {
        closeDialog();
      },
      hideBackButton: true,
    });
  }

  React.useEffect(() => {
    const showAnnouncementAfterSecs = !localStorage.getItem("announcement") 
      ? 15 // first time, 15 seconds after a user visits the website, 
      : 60 * 2; // then repeat every 2 minutes.

    callFundctionAfterSeconds(() => {
      
      localStorage.setItem("announcement", 'y');

      openPopup('Announcement', 
        <div>
          We are currently performing scheduled website maintenance and apologise for any inconvenience.<br/>
          <br/>
          This may impact how you experience our website; if you are having issues with logging in, viewing prices or require assistance please do not hesitate to contact our team – It is our pleasure to support your every enquiry.
        </div>
      )
    }, showAnnouncementAfterSecs);
  }, []) 
  */
  

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MinionPro />
        <Lato />
        <GlobalStyles />
        <DarkSupport />
        <Pace color={''} />
        <WithTheHelpOfHeaven />
        {/* {!isLatestVersion && (
          <div style={{zIndex: 10000000, position: 'absolute', background: 'white', color: 'blue', width: '100%', padding: 8}}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              New version available – click here to update!
            </a>
          </div>
        )} */}
        <Suspense fallback={<Fragment />}>
          {isLayoutHidden && <LoggedOutComponent noLayout />}
          {!isLayoutHidden && <Switch>
            <AuthRoute path="/admin" role="admin">
              <LoggedInComponent />
            </AuthRoute>
            <AuthRoute path="/my-account" role="user">
              <LoggedInUserComponent />
            </AuthRoute>
            <PublicRoute>
              <LoggedOutComponent />
            </PublicRoute>
          </Switch>}
        </Suspense>

        {openGeneralDialog &&
          <MessageDialog
            onClose={closeDialog}
            {...openGeneralDialog}
          />
        }        

      </MuiThemeProvider>
    </Router>
  );
}


// clear cache: https://gist.github.com/kirillshevch/a7d778a6aaa788149ae86a9b313cb0ad
//serviceWorker.register();
serviceWorker.unregister();

export default connect(
  (state) => ({
    openGeneralDialog: state.site.openGeneralDialog,
    isLayoutHidden: state.site.isLayoutHidden,
  }),
  (dispatch) => ({
    SiteActions: bindActionCreators({ ...SiteActions }, dispatch)
  })
)(App);
