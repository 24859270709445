import {
  HOME_SET_ANIMATION_STARTED,
} from "../actions/home";

const initialState = {
  animationStarted: false,
};

export default function home(state = initialState, action) {
  switch (action.type) {
    case HOME_SET_ANIMATION_STARTED:
      return {
        ...state,
        animationStarted: action.data,
      };
    //break;
    default:
      return state;
  }
}
