import {
  SITE_SET_BLOCKS,
  SITE_SET_CCY,
  SITE_SET_CCY_API,
  SITE_SET_OPEN_GENERAL_DIALOG,
  SITE_SET_FXRATES,
  SITE_SET_SOCIAL_LINKS,
  SITE_SET_DIAMOND_AVAILABILITY,
  SITE_SET_DIALOG_SETTINGS,
  SITE_SET_HIDE_LAYOUT,
} from "../actions/site";

const initialState = {
  blocks: false,
  ccy: "GBP",
  ccyAPI: "GBP",
  fxrates: [],
  social_links: [],
  diamond_available: [],
  openGeneralDialog: false,
  isLayoutHidden: false,
};

export default function site(state = initialState, action) {
  switch (action.type) {
    case SITE_SET_BLOCKS:
      return {
        ...state,
        blocks: action.data,
      };
    case SITE_SET_CCY:
      return {
        ...state,
        ccy: action.data,
      };
    case SITE_SET_CCY_API:
      return {
        ...state,
        ccyAPI: action.data,
      };
    case SITE_SET_OPEN_GENERAL_DIALOG:
      return {
        ...state,
        openGeneralDialog: action.data,
      };
    case SITE_SET_FXRATES:
      return {
        ...state,
        fxrates: action.data,
      };
    case SITE_SET_SOCIAL_LINKS:
      return {
        ...state,
        social_links: action.data,
      };
    case SITE_SET_DIAMOND_AVAILABILITY:
      return {
        ...state,
        diamond_available: action.data,
      };
    case SITE_SET_DIALOG_SETTINGS:
      return {
        ...state,
        dialogSettings: action.data,
      };
    case SITE_SET_HIDE_LAYOUT:
      return {
        ...state,
        isLayoutHidden: action.data,
      };
    default:
      return state;
  }
}
