import api from './../../shared/functions/api/';
import { history } from "../../App";

export const DIAMONDS_SET_DATA = "DIAMONDS_SET_DATA";
export const DIAMONDS_SET_COMPARE = "DIAMONDS_SET_COMPARE";
export const DIAMONDS_SET_FAVOURITE = "DIAMONDS_SET_FAVOURITE";
export const DIAMONDS_SET_PAGE_STATE = "DIAMONDS_SET_PAGE_STATE";

export const setDiamondsPageState = (pageState) => {
  return {
    type: DIAMONDS_SET_PAGE_STATE,
    data: pageState,
  };
};

export const setDiamondsData = (diamondsData) => {
  return {
    type: DIAMONDS_SET_DATA,
    data: diamondsData,
  };
};

export const setCompare = (compareList) => {
  return (dispatch) => dispatch({
    type: DIAMONDS_SET_COMPARE,
    data: compareList,
  });
}

export const setFavourite = (favouriteList) => {
  return (dispatch) => dispatch({
    type: DIAMONDS_SET_FAVOURITE,
    data: favouriteList,
  });
}

export const loadDiamondsData = () => {
  return (dispatch) => {
    return api
      .get("/diamonds/data")
      .then((res) => {
        dispatch(setDiamondsData(res.data));
      })
      .catch((err) => {
        console.log(err)
      });
  }
};

